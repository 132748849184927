<template>
  <v-container>
    <v-container class="white-bg mt-48px ct-mw">
      <v-row justify="center" class="text-center">
        <h4 class="navy-text"> Your Result</h4>
      </v-row>
      <v-divider></v-divider>
      <v-row v-if="result" class="d-none d-sm-flex con-400">
        <v-col sm="4" align-self="center">
          <v-img
            v-if="images[hightScore]"
            :src="images[hightScore]"
            height="350"
          ></v-img>
        </v-col>
        <v-col sm="8" align-self="center">
            <apexchart
              class="boder-vi"
              type="radar"
              height="350"
              :options="chartOptions"
              :series="series"
            ></apexchart>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-container>
    <v-container v-if="result" class="d-flex d-sm-none">
      <v-row justify="center" class="px-2">
        <v-col>
          <v-row justify="center">
            <apexchart
              type="radar"
              :options="chartOptions"
              :series="series"
              height="200"
            ></apexchart>
          </v-row>
          <v-row justify="center">
            <v-img
              v-if="images[hightScore]"
              :src="images[hightScore]"
              max-height="200"
              max-width="200"
            ></v-img>
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-container>
    <v-container class="white-bg mt-48px ct-mw d-none d-sm-flex">
      <body>
        <div v-for="(key, index) in keys" v-bind:key="index">
          <v-row class="pt-2">
            <v-col cols="12" sm="2" align-self="center" class="text-center">
              <h1 :class="content[key].color" :id="`cha-${key.toLowerCase()}`">{{key}}</h1>
              <!-- <span class=" font-weight-bold">
                {{parseFloat((result[key] / sumScore) * 100).toFixed(2)}}%
              </span> -->
            </v-col>
            <v-col>
              <p class="base-text">
                <b>{{content[key].meaning}} คือ {{content[key].head1}}</b><br/>
                {{content[key].height}}<br/>
                {{content[key].low}}
            </p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
      </body>
    </v-container>
    <v-container class="d-flex d-sm-none mt-0 pt-0">
      <body>
        <div v-for="(key, index) in keys" v-bind:key="index">
          <v-row class="pt-6">
            <v-col cols="12" sm="2" align-self="center" class="text-center">
              <h1 :class="content[key].color" :id="`cha-${key.toLowerCase()}`">{{key}}</h1>
              <!-- <span class=" font-weight-bold">
                {{parseFloat((result[key] / sumScore) * 100).toFixed(2)}}%
              </span> -->
            </v-col>
            <v-col>
              <p class="base-text">
                <b>{{content[key].meaning}}</b><br/>
                <b>{{content[key].head1}}</b><br/>
                {{content[key].height}}<br/>
                {{content[key].low}}
              </p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
      </body>
    </v-container>
  </v-container>
</template>

<script>
import * as easings from 'vuetify/es5/services/goto/easing-patterns';

export default {
  name: 'serveryResule',
  data() {
    return {
      result: {},
      hightScore: '',
      hightScoreList: [],
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'radar',
        },
        xaxis: {
          categories: ['Openness', 'Courage', 'Endurance', 'Reflection'],
        },
        plotOptions: {
          radar: {
            polygons: {
              strokeColor: '#878787',
              fill: {
                colors: ['#fff'],
              },
            },
          },
        },
        fill: {
          colors: [this.calColor],
          opacity: 0.5,
          type: 'solid',
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            borderRadius: 2,
          },
        },
        yaxis: {
          max: 10,
          min: 0,
          tickAmount: 5,
          labels: {
            show: true,
            style: {
              colors: ['#878787'],
              fontSize: '12px',
              fontWeight: 400,
            },
          },
        },
      },
      sex: '',
      keys: ['C', 'O', 'R', 'E'],
      content: {
        C: {
          character: 'C',
          color: 'red-sur-text',
          meaning: 'Courage',
          head1: 'ศักยภาพของความมุ่งมั่น',
          head2: '',
          body: [],
          height: 'การลงมือกระทำ ความกล้าหาญ การตัดสินใจ ความฉับไวในการตอบสนองต่อสถานการณ์ ไม่กลัวที่จะพลาด และเรียนรู้จากความล้มเหลว',
          low: 'สามารถเอาชนะความกลัวและความวิตกกังวลต่อปัญหาหรือสิ่งที่ยังมาไม่ถึง มีความกล้าที่จะเผชิญกับการขัดแย้งทางความคิด และยืนหยัดต่อการท้าทายตั้งคำถามต่อสิ่งที่คิดว่าไม่ถูกต้อง',
        },
        O: {
          character: 'O',
          color: 'orange-sur-text',
          meaning: 'Openness',
          head1: 'ศักยภาพของการเปิดกว้าง',
          head2: '',
          body: [],
          height: 'ชอบใช้ความคิดในการสร้างสรรค์สิ่งใหม่ๆ มีความใฝ่รู้ พร้อมที่จะพัฒนาตนเอง ค้นคว้าข้อมูลใหม่ๆ เรียนรู้สิ่งต่างๆ อยู่เสมอ',
          low: 'สามารถเปิดกว้างรับฟังความคิดเห็นที่แตกต่างได้ สนุกกับการคิดนอกกรอบ ค้นคว้าหาข้อมูลใหม่ๆ  มองเห็นความเป็นไปได้ที่ข้ามขีดจำกัดในเรื่องต่างๆ ได้ดี',
        },
        R: {
          character: 'R',
          color: 'blue-sur-text',
          meaning: 'Reflection',
          head1: 'ศักยภาพแห่งการใคร่ครวญ',
          head2: '',
          body: [],
          height: 'ทบทวนสิ่งต่างๆ ที่ผ่านเข้ามาในชีวิต มักใส่ใจต่อความคิดความรู้สึกของตนเองและผู้อื่น ชอบตั้งคำถามกับเรื่องราวต่างๆ',
          low: 'สามารถสังเกตเห็นรายละเอียดและความเชื่อมโยงของสิ่งต่างๆ ได้ดี มักนำบทเรียนจากอดีตกลับมาทบทวนเพื่อวิเคราะห์ความเป็นไปได้ในอนาคต',
        },
        E: {
          character: 'E',
          color: 'green-sur-text',
          meaning: 'Endurance',
          head1: 'ศักยภาพแห่งความมั่นคง',
          head2: '',
          body: [],
          height: 'มีความอดทนไม่หวั่นไหวต่ออุปสรรคปัญหา พร้อมที่จะลุกขึ้นเริ่มต้นใหม่อยู่เสมอ มีพลังใจในการฟื้นตัวจากความผิดพลาดล้มเหลว  ไม่ท้อถอยหรือล้มเลิกความตั้งใจง่ายๆ โดยเฉพาะถ้าเป็นสิ่งที่ตนเองเชื่อมั่น',
          low: 'สามารถยืนยันอย่างไม่โลเลต่อหลักการ ความเชื่อและคุณค่าที่ตนเองยึดถือ',
        },
      },
      images: {
        C: require('../../assets/image/core_c.jpg'),
        O: require('../../assets/image/core_o.jpg'),
        R: require('../../assets/image/core_r.jpg'),
        E: require('../../assets/image/core_e.jpg'),
      },
      easings: Object.keys(easings),
      options: {
        duration: 300,
        easing: 'easeInOutCubic',
        offset: 250,
      },
      sumScore: 0,
    };
  },
  methods: {
    calColor() {
      const color = {
        C: '#E20001',
        O: '#F26523',
        R: '#0078D6',
        E: '#8B9C00',
      };
      return color[this.hightScore];
    },
  },
  async mounted() {
    // test
    // const score = {
    //   C: 5,
    //   O: 10,
    //   R: 8,
    //   E: 4,
    // };

    const { score, sex } = this.$route.query;
    this.sex = sex;
    this.result = score;
    const keys = Object.keys(score);
    let hight = 0;
    const viTemp = {
      name: 'Points',
      data: [score.O, score.C, score.E, score.R],
    };
    this.series.push(viTemp);
    keys.forEach((ch) => {
      this.sumScore += score[ch];
    });
    keys.forEach((key) => {
      if (hight < (score[key] / this.sumScore) * 100) {
        hight = (score[key] / this.sumScore) * 100;
        this.hightScore = key;
      }
    });
    const myMaxScore = score[this.hightScore];
    this.hightScoreList = keys.filter(k => score[k] === myMaxScore);
    const ranPosition = Math.floor(Math.random() * this.hightScoreList.length);
    this.hightScore = this.hightScoreList[ranPosition];
  },
};
</script>

<style lang="sass" scoped>
.red-sur-text
  color: #E20001
.blue-sur-text
  color: #0078D6
.green-sur-text
  color: #8B9C00
.orange-sur-text
  color: #F26523
.con-400
  height: 400px
.boder-vi
  border: 1px
  // background-color: #FBF9D0
</style>
